import React from 'react';
import './App.css';

interface ICartPillProps {
    show: boolean;
    calories: number;
    proteins: number;
    carbs: number;
    fats: number;
}

const CartPill = (props: ICartPillProps) => {
  return (
    <div className="floating-cart"
    style={props.show ? {} : { display: 'none' }}    
    >
        <ResultItem icon='🔥' name='Calories' value={props.calories}/>
        <ResultItem icon='💪' name='Protein' value={props.proteins}/>
        <ResultItem icon='🍞' name='Carbs' value={props.carbs}/>
        <ResultItem icon='🥑' name='Fats' value={props.fats}/>
    </div>
  );
}

type ItemProps = {
    icon: string;
    name: string;
    value: number;
}

const ResultItem = ({icon, name, value}: ItemProps) => {
    return (
        <div>
            <div className='pill-icon'>{icon}</div>
            <div>{name}</div>
            <div>{value}</div>
        </div>
    );
}

export default CartPill;
