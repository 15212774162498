import React from 'react';
import './App.css';
import { OrderItem, MealItem } from './App';

interface IResultCardProps {
    item: MealItem;
    order: Map<string, OrderItem>;

    addToOrderFunc(orders: Map<string, OrderItem>): void;
}

type SetOrderer = (orders: Map<string, OrderItem>) => void;

const orderKey = (item: MealItem) => {
    return item.store + item.item
}

const addToOrder = (setOrder: SetOrderer, order: Map<string, OrderItem>, item: MealItem) => {
    const itemKey = orderKey(item)
    let newOrder = new Map<string, OrderItem>(order)

    if (order.has(itemKey)) {
        let item = order.get(itemKey)
        if (item !== undefined) {
            item.count++
            newOrder.set(itemKey, item)
        }
    } else {
        let newOrderItem: OrderItem = {
            store: item.store,
            calories: item.calories,
            proteins: item.proteins,
            fats: item.fats,
            carbs: item.carbs,
            item: item.item,
            count: 1,
        }
        newOrder.set(itemKey, newOrderItem)
    }
    
    setOrder(newOrder)
}

const decrementOrder = (setOrder: SetOrderer, order: Map<string, OrderItem>, item: MealItem) => {
    const itemKey = orderKey(item)
    let newOrder = new Map<string, OrderItem>(order)

    if (order.has(itemKey)) {
        let item = order.get(itemKey)
        if (item !== undefined) {
            if (item.count === 1) {
                newOrder.delete(itemKey)
            } else {
                item.count--
                newOrder.set(itemKey, item)
            }
        }
    }
    setOrder(newOrder)
}

const GetItemOrderCount = (order: Map<string, OrderItem>, item: MealItem) => {
    return Array.from(order.entries())
    .filter(
        (orderItem: [string, OrderItem]) => orderItem[0] === orderKey(item)
    )
    .reduce(
        (acc:number, cv:[string, OrderItem]) => acc + cv[1].count,
        0 
    )
}

const ResultCard = (props: IResultCardProps) => {
  let itemInOrder = props.order.has(orderKey(props.item))
  return (
    <div className={"result-card " + (itemInOrder ? "order-item" : "")} onClick={
        !props.order.has(orderKey(props.item))
        ? () => addToOrder(props.addToOrderFunc, props.order, props.item) 
        : () => {}
        }>
        <div className='result-card-id'>
            <div>
                <h3 className='result-card-heading'>{props.item.item}</h3>
                <div className='subheading'>{props.item.store}</div>
            </div>
            {itemInOrder && <div className='result-card-actions'>
                <span 
                    className="result-card-button" 
                    onClick={
                        () => decrementOrder(props.addToOrderFunc, props.order, props.item)
                    }
                >
                    { 
                        GetItemOrderCount(props.order, props.item) === 1 
                            ? "🗑️" 
                            : "-"
                    }    
                </span>  
                    {GetItemOrderCount(props.order, props.item)}  
                <span 
                    className="result-card-button" 
                    onClick={
                        itemInOrder
                        ? () => addToOrder(props.addToOrderFunc, props.order, props.item) 
                        : () => {}
                    }
                >+</span>
            </div>}
        </div>
        <ul>
            <ResultItem icon='🔥' name='Calories' value={props.item.calories}/>
            <ResultItem icon='💪' name='Protein' value={props.item.proteins}/>
            <ResultItem icon='🍞' name='Carbs' value={props.item.carbs}/>
            <ResultItem icon='🥑' name='Fats' value={props.item.fats}/>
        </ul>
    </div>
  );
}

type ItemProps = {
    icon: string;
    name: string;
    value: number;
}

const ResultItem = ({icon, name, value}: ItemProps) => {
    return (
        <li><span className='icon'>{icon}</span><span>{name}</span><span>{value}</span></li>
    );
}

export default ResultCard;
